<template>
  <div class="centerbox">
    <ul class="teamlist" v-loading="loading">
      <li v-for="(item,index) in teamData">
        <div class="onebox">
          <router-link tag="span" :to="{name:'teamsBook',params:{tid: item.Tid}}" class="left">
            <img :src="item.Cover" alt />
          </router-link>
          <!-- {name:'teamsBook',params:{tid: item.Tid}} -->
          <div class="right">
            <div class="teammember"></div>
            <h2 class="titleh2">
              <router-link tag="span" :to="{name:'teamsBook',params:{tid: item.Tid}}">{{item.Team}}</router-link>
            </h2>
            <div class="center">
              <div class="numleft">
                <i class="iconfont iconxingzhuang8"></i>
                <span class="num">{{item.Members}}</span>
              </div>
              <div class="btnbox">
                <span @click="handelquitteam(item.Tid)">退出</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <p v-if="showNomore" class="nomore">暂无数据</p>
    <el-pagination
      background
      v-if="total>pageCount"
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
import { teamOursRequest, teamJoinRequest ,teamBolterRequest} from "@/api/project.js";
export default {
  data() {
    return {
      teamData: [],
      currentPage: 1,
      total: "",
      pageCount: 10,
      loading: true,
      searchInp: "",
      showNomore: false,
    };
  },
  created() {
    this.getTeam(1);
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getTeam(val) {
      try {
        this.loading = true;
        this.showNomore = false;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        val = val || "1";
        const result = await teamOursRequest({
          page: val,
        });
        if (result.status == 200) {
          this.loading = false;
          this.teamData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
          if (result.data.list.length == 0) this.showNomore = true;
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getTeam(val);
    },
    async joinTeam(tid, reason) {
      try {
        const result = await teamJoinRequest({
          tid: tid,
          reason: reason,
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`,
          });
        } else {
          this.$message({
            type: "info",
            message: `${result.message}`,
          });
        }
      } catch (err) {}
    },
    handelJoinTeam(id) {
      var _this = this;
      this.$prompt("请输入申请理由", "加入该团队", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{1,120}/,
        inputErrorMessage: "请输入申请理由",
        center: true,
      })
        .then(({ value }) => {
          _this.joinTeam(id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handelquitteam(){
      this.$confirm('此操作将退出该团队?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.quitteam()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    async quitteam(tid){
       try {
          const result = await teamBolterRequest({
          tid: tid
        });
        if (result.status == 200) {
          this.getTeam(1);
          this.$message({
            type: "success",
            message: `${result.message}`,
          });
        } else {
          this.$message({
            type: "info",
            message: `${result.message}`,
          });
        }
       }catch(e){
         console.log(e)
       }
    }
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  margin: -15px;
}

/deep/.searchteam {
  width: 460px;
  margin: -30px auto 30px;
  display: block;
  .el-input__inner {
    border-radius: 50px;
    height: 60px;
    padding: 0 50px 0 30px;
  }
  .el-input__suffix {
    right: 25px;
    color: #3385ff;
    line-height: 60px;
    // font-size: 34px;
    cursor: pointer;
    .iconfont {
      font-size: 20px;
    }
  }
}
.teamlist {
  margin: 0 -15px;
  overflow: hidden;
  clear: both;
  & > li {
    padding: 15px;
    width: 25%;
    float: left;
    box-sizing: border-box;

    .onebox {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 10px #e0e0e0;
      padding: 5px;
      height: 260px;
    }
  }
  .left {
    width: 100%;
    height: 176px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    cursor: pointer;
    color: #666;
    .titleh2 {
      font-size: 14px;
      padding: 15px;
      .addteam {
        float: right;
      }
    }
    .center{
      padding:0 10px;
    }
    .btnbox {
      float: right;
      font-size: 13px;
      color: #3385ff;

      span {
        margin-left: 10px;
        display: inline-block;
      }
    }
    .numleft {
      float: left;
      line-height: 20px;
      i {
        font-size: 16px;
        color: #3385ff;
        margin-right:5px;
      }
    }
    .textp {
      font-size: 13px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .teammember {
      overflow: hidden;
      margin-top: 9px;
      position: absolute;
      top: 100px;
      left: 50%;
      margin-left: -47px;
      span {
        width: 94px;
        height: 94px;
        border: 1px solid #e9e9e9;
        border-radius: 100px;
        overflow: hidden;
        float: left;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          float: left;
        }
      }
    }
  }
}
.bannerbox {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.nomore {
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>