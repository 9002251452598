<template>
  <div class="centerwrap">
    <div class="centerbox">
      <el-menu router :default-active="$route.path" class="" mode="horizontal">
        <el-menu-item index="/persionTeam">团队</el-menu-item>
      </el-menu>
      <team-list></team-list>
    </div>
  </div>
</template>
<script>
import TeamList from '../components/teamlist.vue'
export default {
  components: {
    TeamList
  },
  data() {
    return {};
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative; 
 padding: 0 20px;
}
.centerbox{
    width: 100%;

}
/deep/.collapse {
  i {
    color: #aaa;
  }
}
/deep/.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
</style>